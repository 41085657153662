import dynamic from 'next/dynamic'
import Head from 'next/head'
import { getFeatureDisabledElements, getFeatureDisabledRoutes } from 'utils/next/routes'
import { LayoutProvider } from 'providers/LayoutProvider'
import { HeaderProvider } from 'providers/HeaderProvider'
import styles from 'components/Layout/Layout.module.scss'
import { useRouter } from 'next/router'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
const Alchemer = dynamic(() => import('components/Alchemer'))
import { som } from 'constants/schemaOrganizationMarkup'
import { useState } from 'react'
const FFAMBanner = dynamic(() => import('components/FFAMBanner/FFAMBanner'))
import { isFFAMDisabled } from 'constants/ffam'
const HeaderContainer = dynamic(() => import('components/Header/HeaderContainer/HeaderContainer'))
import { getTwitterImage, getOgTypeMetaTag, getPageMetaTitleForRoute } from 'utils/metadata'

const FooterContainer = dynamic(() => import('components/Footer/FooterContainer/FooterContainer'))
import { useIsBlog } from 'utils/routes'
import { PRD_ENV } from 'constants/environments'
const SiteWideBanner = dynamic(() => import('components/SiteWideBanner/SiteWideBanner'))
import { GlobalProvider } from 'providers/GlobalProvider'
import { convertStringBoolean } from 'utils/shared'

export default function Layout({
  canonicalLink,
  children,
  featureToggleObject,
  pageDisclosure = '',
  pageMetaData = [],
  metaDescription,
  pageMetaTitle,
  isFFAMReferrer,
  ffamReferrerContent,
  footer,
  secondaryDisclosures = [],
  header,
  pageTitle,
  publicProcessDotEnv,
  emergencyBanners,
  isMobileUA,
  pageConfig,
}) {
  // Consider it a lower environment if we have an app env being passed and it's not set as
  // `production`. If it matches production or no env was passed, do not block bots
  const appEnv = publicProcessDotEnv?.NEXT_PUBLIC_APP_ENV
  const isLowerEnv = appEnv ? appEnv !== PRD_ENV : false

  const disabledRoutes = getFeatureDisabledRoutes(featureToggleObject)
  const disabledElements = getFeatureDisabledElements(featureToggleObject)

  const usesSiteWideBanner = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_SITE_WIDE_BANNER)

  const [closeFFAMBanner, setCloseFFAMBanner] = useState(false)

  const router = useRouter()
  // TODO: Fix this. The name and intention of useIsBlog is confusing and duplicates useRouter logic
  const route = useIsBlog()

  const hasUTM = router.asPath ? router.asPath.includes('utm_') : false

  /**
   * The object below is used by home-equity-loan-mp to display phone number in the header
   * based on utm_source and utm_content. Phone number data and expected utms are stored
   * in the pageConfig object in the Home Equity Loan MP contentful entry.
   * Implemented by PXP team.
   * Contact: Shreyas Dorle, Brandon Chapman
   */
  let customPhoneData
  if (pageConfig?.customPhoneData) {
    customPhoneData = {
      utm_source: router?.query?.utm_source ?? null,
      utm_content: router?.query?.utm_content ?? null,
      ...pageConfig.customPhoneData,
    }
  }

  const dynamicPageMetaTitle = getPageMetaTitleForRoute(router, pageMetaTitle, pageTitle)
  const image = getTwitterImage(route, children)

  const handleCloseDialog = () => {
    setCloseFFAMBanner(true)
  }

  const renderEmergencyBanners = () => {
    const list = []
    for (let i = 0; i < emergencyBanners.length; i++) {
      const element = emergencyBanners[i]
      list.push(
        <SiteWideBanner
          title={element?.title}
          message={element?.message}
          handleCloseDialog={() => {}}
        />
      )
    }
    return list
  }

  return (
    <div className={styles['page-container']}>
      <Head data-testid="layout-head">
        <title data-testid="layout-title">
          {dynamicPageMetaTitle
            ? `${dynamicPageMetaTitle} ${router.pathname === '/' ? '' : '| Achieve'}`
            : `Achieve`}
        </title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" data-testid="layout-head-canonical-link" href={`${canonicalLink}`} />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta charSet="UTF-8" />
        {
          /* Add No follow for pages with utm parameters */
          (isLowerEnv || hasUTM) && <meta name="robots" content="noindex, nofollow" />
        }

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@achievecom" />
        <meta name="twitter:title" content={`${dynamicPageMetaTitle}`} />
        {metaDescription && <meta name="twitter:description" content={`${metaDescription}`} />}
        <meta name="twitter:image" content={image} />
        {/* Facebook */}
        <meta name="og:image" content={image} />
        <meta name="og:locale" content="United States" />
        <meta name="og:title" content={`${dynamicPageMetaTitle}`} />
        <meta name="og:url" content={`${canonicalLink}`} />
        <meta name="og:site_name" content="Achieve" />

        {getOgTypeMetaTag(route)}
        {metaDescription && <meta name="og:description" content={`${metaDescription}`} />}

        {/* Schema Organization Markup */}
        {router.pathname === '/' && (
          <script
            className="structured-data-list"
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: som }}
            defer
          />
        )}

        {/*Meta data description tag*/}
        {metaDescription && <meta name="description" content={metaDescription} />}

        {/*Meta data other tags*/}
        {pageMetaData
          .filter(({ fields: { name } }) => name !== 'viewport')
          .map(({ fields: { name, content } }) => (
            <meta key={name} name={name} content={content} />
          ))}
      </Head>

      <LayoutProvider isMobileUA={isMobileUA}>
        <GlobalProvider>
          <HeaderProvider>
            <HeaderContainer
              content={header}
              disabledRoutes={disabledRoutes}
              disabledElements={disabledElements}
              noHeaderCta={pageConfig?.noHeaderCta}
              showNav={convertStringBoolean(pageConfig?.showNav)}
              showSubNav={convertStringBoolean(pageConfig?.showSubNav)}
              showSignIn={convertStringBoolean(pageConfig?.showSignIn)}
              customPhoneData={customPhoneData ?? null}
            />
          </HeaderProvider>
          <div className={styles['max-width-container']} data-narrow={true}>
            <main className={styles.main} id="main-layout">
              {!closeFFAMBanner && !isFFAMDisabled() && usesSiteWideBanner && (
                <FFAMBanner
                  isFFAMReferrer={isFFAMReferrer}
                  ffamReferrerContent={ffamReferrerContent}
                  handleCloseDialog={handleCloseDialog}
                />
              )}
              {emergencyBanners?.length > 0 && renderEmergencyBanners()}
              {children}
            </main>
          </div>
          <FooterContainer
            pageDisclosure={pageDisclosure || ''}
            disabledRoutes={disabledRoutes}
            content={footer}
            secondaryDisclosures={secondaryDisclosures}
            data-testid="footer"
            showNav={convertStringBoolean(pageConfig?.showNav)}
            showFooterCopyRightRow={convertStringBoolean(pageConfig?.showFooterCopyRightRow)}
          />
        </GlobalProvider>
      </LayoutProvider>

      {/* Alchemer survey script */}
      {disabledElements[FEATURE_TOGGLES.ACX_WEB_ENABLE_ALCHEMER_SURVEY] && <Alchemer />}
    </div>
  )
}
